import apiService from "@/http/apiService";

const shared = {
  namespaced: true,

  state: {
    noteByMemberId: [],
    documentByMemberId: [],
    documents: [],
    notes: [],
    workflowComponent: [],
    availableNextStep: [],
    documentsRequiredGet: [],
    documentsRequired: [],
    reports: [],
    reportData: [],
    communicationDocument: [],
    nextSteps: [],

    // workflowComponent:
    //   JSON.parse(localStorage.getItem("workflowComponent")) || [],

    // availableNextStep:
    //   JSON.parse(localStorage.getItem("availableNextStep")) || [],

    // documentsRequiredGet:
    //   JSON.parse(localStorage.getItem("documentsRequiredGet")) || [],
  },

  mutations: {
    setNextSteps(state, data) {
      state.nextSteps = data;
    },
    setCommunicationDocument(state, data) {
      state.communicationDocument = data;
    },
    setReports(state, data) {
      state.reports = data;
    },
    setReportData(state, data) {
      state.reportData = data;
    },
    setGetDocumentRequired(state, data) {
      state.documentsRequiredGet = data;
      //localStorage.setItem("documentsRequiredGet", JSON.stringify(data));
    },

    // setAvailableNextStep
    setAvailableNextStep(state, data) {
      state.availableNextStep = data;
      //localStorage.setItem("availableNextStep", JSON.stringify(data));
    },

    // setAddNote
    setAddNote(state, data) {
      state.notes = data;
    },

    // setNoteByMemberId
    setNoteByMemberId(state, data) {
      state.noteByMemberId = data;
    },

    // setDocumentByMemberId
    setDocumentByMemberId(state, data) {
      state.documentByMemberId = data;
    },

    // setDocument
    setDocument(state, data) {
      state.documents = data;
    },

    // setWorkflowComponent
    setWorkflowComponent(state, data) {
      state.workflowComponent = data;
      localStorage.setItem("workflowComponent", JSON.stringify(data));
    },

    setDocumentRequired(state, data) {
      state.documentsRequired = data;
      localStorage.setItem("documentsRequiredGet", JSON.stringify(data));
    },

    resetState(state) {
      state.noteByMemberId = [];
      state.documentByMemberId = [];
      state.documents = [];
      state.notes = [];
      state.workflowComponent = [];
      state.availableNextStep = [];
      state.documentsRequiredGet = [];
      state.documentsRequired = [];
    },
  },

  actions: {
    async fetchNextSteps({commit}, { currentstate, nextstate }) {
      try {
        const response = await apiService.getNextSteps(currentstate, nextstate);
        commit("setNextSteps", response.data);
        return response.data;

      } catch (error) {
        console.log(error);
      }
    },

    // getAvailableNextStep
    async getAvailableNextStep({ commit }, { currentstate, workflowid }) {
      try {
        const response = await apiService.getAvailableNextStep(
          currentstate,
          workflowid
        );

        commit("setAvailableNextStep", response.data);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    // addNote
    async addNote({ commit }, data) {
      try {
        const response = await apiService.addNote(data);
        commit("setAddNote", response.data);

        return response;
      } catch (error) {
        console.log("addNote", error);
      }
    },

    // getNoteByMemberId
    async getNoteByMemberId({ commit }, memberClaimId) {
      try {
        const response = await apiService.getNoteByMemberId(memberClaimId);
        commit("setNoteByMemberId", response.data);
      } catch (error) {
        console.log("getNoteByMemberId", error);
      }
    },

    // getDocumentByMemberId
    async getDocumentByMemberId({ commit }, memberClaimId) {
      try {
        const response = await apiService.getDocumentByMemberId(memberClaimId);
        commit("setDocumentByMemberId", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getDocumentByMemberId", error);
      }
    },

    // addDocument
    async addDocument({ commit }, data) {
      try {
        const response = await apiService.addDocument(data);

        commit("setDocument", response.data);

        return response;
      } catch (error) {
        console.log("addDocument", error);
      }
    },

    // getWorkflowComponent
    async getWorkflowComponent({ commit }, { workFlowId, workFlowStateId }) {
      try {
        const response = await apiService.getWorkflowComponent(
          workFlowId,
          workFlowStateId
        );

        commit("setWorkflowComponent", response.data);

        return response;
      } catch (error) {
        console.log("getWorkflowComponent", error);
      }
    },

    // Add required Documents
    async addDocumentRequired({ commit }, data) {
      try {
        const response = await apiService.addDocumentRequired(data);
        commit("setDocumentRequired", data);

        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addDocumentRequired", error);
      }
    },

    async getDocumentRequired({ commit }, { workflowId, workflowstateId }) {
      try {
        const response = await apiService.getDocumentRequired(
          workflowId,
          workflowstateId
        );
        commit("setGetDocumentRequired", response.data);

        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getDocumentRequired", error);
      }
    },

    async getDocuments({ commit }, memberClaimId) {
      try {
        const response = await apiService.getDocuments(memberClaimId);
        commit("setGetDocuments", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getDocuments", error);
      }
    },

    async createReport({ commit }, data) {
      try {
        const response = await apiService.addReport(data);
        commit("setReportData", response.data);
        return response;
      } catch (error) {
        console.log("addReport", error);
      }
    },
    async fetchReports({ commit }, data) {
      try {
        const response = await apiService.getReports(data);
        commit("setReports", response.data);
        return response;
      } catch (error) {
        console.log("addReport", error);
      }
    },
    async runReport({ commit }, { reportid, startdate, enddate }) {
      try {
        const response = await apiService.runReport(
          reportid,
          startdate,
          enddate
        );
        commit("setReportData", response.data);
        return response;
      } catch (error) {
        console.log("runReport", error);
      }
    },
    async editReport(_, data) {
      try {
        const response = await apiService.editReport(data);
        //  commit("setReportData", response.data);
        return response;
      } catch (error) {
        console.log("editReport", error);
      }
    },

    // deleteDocument
    async deleteDocument(_, documentId) {
      try {
        console.log("documentId", documentId);
        const response = await apiService.deleteDocument(documentId);
        return response;
      } catch (error) {
        console.log("deleteDocument", error);
      }
    },
  },
  getters: {
    getNextSteps(state) {
      return state.nextSteps;
    },
    getCommunicationDocument(state) {
      return state.communicationDocument;
    },
    getReportData(state) {
      return state.reportData;
    },
    getReports(state) {
      return state.reports;
    },
    getDocuments(state) {
      return state.documentByMemberClaimId;
    },

    getDocumentRequired(state) {
      return state.documentsRequiredGet;
    },

    // getAvailableNextStep
    getAvailableNextStep(state) {
      return state.availableNextStep;
    },

    // getNoteByMemberId
    getNoteByMemberId(state) {
      return state.noteByMemberId;
    },

    // getDocumentByMemberId
    getDocumentByMemberId(state) {
      return state.documentByMemberId;
    },

    // getDocument
    getDocument(state) {
      return state.documents;
    },

    // getWorkflowComponent
    getWorkflowComponent(state) {
      return state.workflowComponent;
    },
  },
};

export default shared;
