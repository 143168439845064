import apiService from "@/http/apiService";

const sideBar = {
  namespaced: true,

  state: {
    sideBarManuClaims: [],
    sideBarManuStates: [],
    workFlowStates: [],
    workFlowStatesByUser: [],
    sideBarManuSubmitStates: "",
    claimState: "",
    claimStateName: "",
    workflowId: null,
    clickedWorkFlowStateId: null,
    workFlowStatuses: [],

    //claimState: JSON.parse(localStorage.getItem("claimState")) || "claim",

    // claimStateName:
    // JSON.parse(localStorage.getItem("claimStateName")) || "Claims",

    // workflowId: localStorage.getItem("workflowId") || null,

    // clickedWorkFlowStateId:
    //   JSON.parse(localStorage.getItem("clickedWorkFlowStateId")) || 1,

    // workFlowStatuses:
    // JSON.parse(localStorage.getItem("workFlowStatuses")) || [],
  },

  mutations: {
    // setWorkFlowStatuses
    setWorkFlowStatuses(state, data) {
      state.workFlowStatuses = data;
    },

    // setSideBarManu
    setSideBarManu(state, data) {
      state.sideBarManuClaims = data;
    },

    // setWorkFlowStates
    setWorkFlowStates(state, data) {
      state.workFlowStates = data;
    },

    // setWorkFlowStatesByUser
    setWorkFlowStatesByUser(state, data) {
      state.workFlowStatesByUser = data;
    },

    // setSideBarManuClaims
    setSideBarManuClaims(state, data) {
      state.sideBarManuStates = data;
    },

    // setSideBarManuSubmitStates
    setSideBarManuSubmitStates(state, data) {
      state.sideBarManuSubmitStates = data;
    },

    // setClaimStateName
    setClaimStateName(state, data) {
      state.claimStateName = data;
      //localStorage.setItem("claimStateName", JSON.stringify(data));
    },
    setWorkflowStateId(state, data) {
      state.clickedWorkFlowStateId = data;
      //localStorage.setItem("clickedWorkFlowStateId", JSON.stringify(data));
    },

    // setClaimStateName
    setClaimState(state, data) {
      state.claimState = data;
      //localStorage.setItem("claimState claimStateName", JSON.stringify(data));
    },

    // setWorkflowId
    setWorkflowId(state, data) {
      state.workflowId = data;
      //localStorage.setItem("workflowId", data);
    },

    resetState(state) {
      state.sideBarManuClaims = [];
      state.sideBarManuStates = [];
      state.workFlowStates = [];
      state.sideBarManuSubmitStates = "";
      state.claimState = "claim";
      state.claimStateName = "Claims";
      state.workflowId = null;
      state.workFlowStatuses = [];
    },
  },

  actions: {
    // getWorkFlowStatuses
    async getWorkFlowStatuses({ commit }, workFlowId) {
      try {
        const response = await apiService.getWorkFlowStatuses(workFlowId);
        commit("setWorkFlowStatuses", response.data);
      } catch (error) {
        console.log("getWorkFlowStatuses", error);
      }
    },

    // getSideBarManuClaims
    async getSideBarManuClaims({ commit }) {
      try {
        const response = await apiService.getSideBarManuClaims();
        commit("setSideBarManu", response.data);
      } catch (error) {
        console.log("getSideBarManuClaims", error);
      }
    },

    //getWorkFlowStates
    async getWorkFlowStates({ commit }, id) {
      try {
        const response = await apiService.getWorkFlowStates(id);

        commit("setWorkFlowStates", response.data);
      } catch (error) {
        console.log("getWorkFlowStates", error);
      }
    },

    //getWorkFlowStatesByUser
    async getWorkFlowStatesByUser({ commit }, { workflowId, user }) {
      try {
        const response = await apiService.getWorkFlowStatesByUser(
          workflowId,
          user
        );
        console.log(response.data);
        commit("setWorkFlowStatesByUser", response.data);
      } catch (error) {
        console.log("getWorkFlowStatesByUser", error);
      }
    },

    // submitnextsteps
    // submitClaimToLegalClaim
    async submitClaimToLegalClaim({ commit }, data) {
      try {
        const response = await apiService.submitClaimToLegalClaim(data);
        commit("setSideBarManuSubmitStates", response.data);
        return response;
      } catch (error) {
        console.log("submitClaimToLegalClaim", error);
      }
    },
  },

  getters: {
    getClickedWorkflowStateId(state) {
      return state.clickedWorkFlowStateId;
    },
    // getWorkFlowStatuses
    getWorkFlowStatuses(state) {
      return state.workFlowStatuses;
    },

    // getSideBarManuClaims
    getSideBarManuClaims(state) {
      return state.sideBarManuClaims;
    },

    // getWorkFlowStates
    getWorkFlowStates(state) {
      return state.workFlowStates;
    },

    getWorkFlowStatesByUser(state) {
      return state.workFlowStatesByUser;
    },
    //getSideBarWokflowState
    getSideBarWokflowState(state) {
      return state.sideBarManuStates;
    },

    // getSideBarManuSubmitStates
    getSideBarManuSubmitStates(state) {
      return state.sideBarManuSubmitStates;
    },

    // getClaimStateName
    getClaimStateName(state) {
      return state.claimStateName;
    },

    // getClaimState
    getClaimState(state) {
      return state.claimState;
    },
    getWorkflowId(state) {
      return state.workflowId;
    },
  },
};

export default sideBar;
