import apiService from "@/http/apiService";

const workflow = {
  namespaced: true,

  state: {
    addWorkflow: {},
    addWorkflowState: {},
    addworkflowComponent: {},
    workflowById: {},
    getWorkflow: {},
    workFlowStates: [],
    workflows: [],
    workFlowStatesForDocuments: [],
    workFlowStatesForAddingNextStep: [],
    workflowStatuses: [],
    workflowStatusesForAddingFields: [],
    WorkflowComponentsByid: [],
    workFlowStatesForAddingReciepients: [],
    workflowStatusesForAddingReciepients: [],
    requiredRecipients: [],
    workflowStatusesForAddingSteps: [],
    availableNextStep: [],
    workflowStatusesForAddingDocuments: [],
  },

  mutations: {
    setWorkflowStatusesForAddingDocuments(state, data) {
      state.workflowStatusesForAddingDocuments = data;
    },
    setAvailableNextStep(state, data) {
      state.availableNextStep = data;
    },
    setWorkflowStatusesForAddingSteps(state, data) {
      state.workflowStatusesForAddingSteps = data;
    },
    setGetRequiredRecipients(state, data) {
      state.requiredRecipients = data;
    },
    setWorkflowStatusesForAddingReciepients(state, data) {
      state.workflowStatusesForAddingReciepients = data;
    },
    setWorkFlowStatesForAddingReciepients(state, data) {
      state.workFlowStatesForAddingReciepients = data;
    },
    setGetWorkflowComponentsByid(state, data) {
      state.WorkflowComponentsByid = data;
    },
    setGetWorkflowStatusesForAddingFields(state, data) {
      state.workflowStatusesForAddingFields = data;
    },
    setGetWorkflowStatuses(state, data) {
      state.workflowStatuses = data;
    },
    setWorkFlowStatesForAddingNextStep(state, data) {
      state.workFlowStatesForAddingNextStep = data;
    },
    setWorkFlowStatesForDocuments(state, data) {
      state.workFlowStatesForDocuments = data;
    },

    setAddWorkflow(state, data) {
      state.addWorkflow = data;
    },

    setGetWorkflow(state, data) {
      state.workflows = data;
    },

    setAddState(state, data) {
      state.addState = data;
    },

    setAddWorkflowComponent(state, data) {
      state.addworkflowComponent = data;
    },

    setWorkflowById(state, data) {
      state.workflowById = data;
    },
    setAddWorkflowState(state, data) {
      state.addworkflowState = data;
    },
    setGetWorkflowBy(state, data) {
      state.getWorkflow = data;
    },
    setWorkFlowStates(state, data) {
      state.workFlowStates = data;
    },

    resetState(state) {
      // state.addWorkflow = {};
      // state.addWorkflowState = {};
      // state.addworkflowComponent = {};
      // state.workflowById = {};
      // state.getWorkflow = {};
      // state.workFlowStates = [];
      // state.workflows = [];
      // state.workFlowStatesForDocuments = [];
      // state.workFlowStatesForAddingNextStep = [];
      // state.workflowStatuses = [];
      // state.workflowStatusesForAddingFields = [];
      // state.WorkflowComponentsByid = [];
      // state.workFlowStatesForAddingReciepients = [];
      // state.workflowStatusesForAddingReciepients = [];
      // state.requiredRecipients = [];
      // state.workflowStatusesForAddingSteps = [];
      // state.availableNextStep = [];
      // state.workflowStatusesForAddingDocuments = [];

      state.addWorkflow = {};
      state.addWorkflowState = {};
      state.addworkflowComponent = {};
      state.workflowById = {};
      state.getWorkflow = {};
      state.workFlowStates = [];
      state.workflows = [];
      state.workFlowStatesForDocuments = [];
      state.workFlowStatesForAddingNextStep = [];
      state.workflowStatuses = [];
      state.workflowStatusesForAddingFields = [];
    },
  },
  actions: {
    //addworkflow
    async addWorkflow({ commit }, data) {
      try {
        const response = await apiService.addWorkflow(data);
        commit("setAddWorkflow", response.data);

        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addWorkflow", error);
      }
    },

    async getWorkflows({ commit }) {
      try {
        const response = await apiService.getSideBarManuClaims();
        commit("setGetWorkflow", response.data);
      } catch (error) {
        console.log("getWorkflows", error);
      }
    },

    async addWorkflowState({ commit }, data) {
      try {
        const response = await apiService.addWorkflowState(data);
        commit("setAddWorkflowState", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addWorkflowState", error);
      }
    },

    async getWorkFlowStates({ commit }, id) {
      try {
        const response = await apiService.getWorkFlowStates(id);
        commit("setWorkFlowStates", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkFlowStates", error);
      }
    },

    async getWorkFlowStatesForDocuments({ commit }, id) {
      try {
        const response = await apiService.getWorkFlowStates(id);
        commit("setWorkFlowStatesForDocuments", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkFlowStates", error);
      }
    },

    async getWorkFlowStatesForAddingNextStep({ commit }, id) {
      try {
        const response = await apiService.getWorkFlowStates(id);
        commit("setWorkFlowStatesForAddingNextStep", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkFlowStates", error);
      }
    },

    async getWorkflowStatuses({ commit }, id) {
      try {
        const response = await apiService.getWorkflowStatuses(id);
        commit("setGetWorkflowStatuses", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkflowStatuses", error);
      }
    },

    async addWorkflowComponent({ commit }, data) {
      try {
        const response = await apiService.addWorkflowComponentAPI(data);
        commit("setAddworkflowComponent", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.error("addworkflowComponent ", error);
      }
    },
    async getClaimsByState({ commit }, { claimState, workFlowId }) {
      try {
        const response = await apiService.getClaimsByState(
          claimState,
          workFlowId
        );
        commit("setClaimsByState", response.data);
      } catch (error) {
        console.log("getClaimsByState", error);
      }
    },

    async getWorkflowComponentsByid(
      { commit },
      { workFlowId, workFlowStateId }
    ) {
      try {
        const response = await apiService.getWorkflowComponent(
          workFlowId,
          workFlowStateId
        );
        commit("setGetWorkflowComponentsByid", response.data);

        return response;
      } catch (error) {
        console.error("getWorkflowComponentsByid ", error);
      }
    },

    async addWorkflowSteps({ commit }, data) {
      try {
        const response = await apiService.addWorkflowSteps(data);
        commit("setAddworkflowSteps", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.error("addworkflowSteps ", error);
      }
    },
    async getWorkflowStatusesForAddingFields({ commit }, id) {
      try {
        const response = await apiService.getWorkflowStatuses(id);
        commit("setGetWorkflowStatusesForAddingFields", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkflowStatuses", error);
      }
    },
    async fetchWorkFlowStatesForAddingReciepients({ commit }, id) {
      try {
        const response = await apiService.getWorkFlowStates(id);
        commit("setWorkFlowStatesForAddingReciepients", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkFlowStates", error);
      }
    },
    async fetchWorkFlowStatusesForAddingReciepients({ commit }, id) {
      try {
        const response = await apiService.getWorkflowStatuses(id);
        commit("setWorkflowStatusesForAddingReciepients", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkflowStatuses", error);
      }
    },
    async addRecipients({ commit }, data) {
      try {
        const response = await apiService.addRecipients(data);
        commit("setAddRecipients", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.error("addRecipients ", error);
      }
    },
    async fetchRequiredRecipients({ commit }, { workFlowId, workFlowStateId }) {
      try {
        const response = await apiService.getRequiredRecipients(
          workFlowId,
          workFlowStateId
        );
        commit("setGetRequiredRecipients", response.data);

        return response;
      } catch (error) {
        console.error("fetchRequiredRecipients ", error);
      }
    },
    async fetchWorkFlowStatusesForAddingSteps({ commit }, id) {
      try {
        const response = await apiService.getWorkflowStatuses(id);
        commit("setWorkflowStatusesForAddingSteps", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getWorkflowStatuses", error);
      }
    },
    async updateWorkflowState(_, data) {
      try {
        const response = await apiService.updateWorkflowState(data);

        return response;
      } catch (error) {
        console.error("updateWorkflowState ", error);
      }
    },
    async fetchAvailableNextSteps({ commit }, { currentstate, nextstate }) {
      try {
        const response = await apiService.getNextSteps(currentstate, nextstate);
        commit("setAvailableNextStep", response.data);

        return response;
      } catch (error) {
        console.error("fetchAvailableNextSteps ", error);
      }
    },

    async fetchStatusesForAddingDocuments({ commit }, id) {
      try {
        const response = await apiService.getWorkflowStatuses(id);
        commit("setWorkflowStatusesForAddingDocuments", response.data);

        return response;
      } catch (error) {
        console.log("getWorkflowStatuses", error);
      }
    },
    async addClaimType(_, data) {
      try {
        const response = await apiService.addClaimType(data);

        return response;
      } catch (error) {
        console.error("addClaimType ", error);
      }
    },
  },

  getters: {
    getWorkflowStatusesForAddingDocuments(state) {
      return state.workflowStatusesForAddingDocuments;
    },
    getAvailableNextSteps(state) {
      return state.availableNextStep;
    },
    getWorkFlowStatesForAddingStates(state) {
      return state.workFlowStatesForAddingStates;
    },
    getWorkFlowStatusesForAddingNextStep(state) {
      return state.workflowStatusesForAddingSteps;
    },
    getRequiredRecipients(state) {
      return state.requiredRecipients;
    },
    getWorkflowStatusesForAddingReciepients(state) {
      return state.workflowStatusesForAddingReciepients;
    },
    getWorkFlowStatesForAddingReciepients(state) {
      return state.workFlowStatesForAddingReciepients;
    },
    getWorkflowComponentsByid(state) {
      return state.WorkflowComponentsByid;
    },
    getWorkflowStatusesForAddingFields(state) {
      return state.workflowStatusesForAddingFields;
    },
    getWorkflowStatuses(state) {
      return state.workflowStatuses;
    },
    getWorkFlowStatesForAddingNextStep(state) {
      return state.workFlowStatesForAddingNextStep;
    },
    getWorkFlowStatesForDocuments(state) {
      return state.workFlowStatesForDocuments;
    },
    //get workflow
    getWorkflow(state) {
      return state.getWorkflow;
    },
    getWorkFlowStates(state) {
      return state.workFlowStates;
    },

    getWorkflows(state) {
      return state.workflows;
    },
  },
};

export default workflow;
